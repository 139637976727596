/**
 * Theme Utilities and Event Handlers
 * Handles contrast calculations and responsive search functionality
 */

// Constants
const MOBILE_BREAKPOINT = 960;

// DOM Elements
const $window = $(window);
const $document = $(document);

/**
 * Updates contrast colors based on theme accent color
 * Calculates appropriate contrast for accessibility
 */
function updateContrastColor() {
    const html = document.documentElement;
    const htmlStyle = html.getAttribute('style');
    
    // Parse the CSS variables from style attribute
    const cssVars = {};
    if (htmlStyle) {
        htmlStyle.split(';').forEach(style => {
            const [name, value] = style.split(':').map(s => s.trim());
            if (name && name.startsWith('--')) {
                cssVars[name] = value;
            }
        });
    }

    // Get the lightness value, checking --cb-accent-9-l first
    let lightness;
    if (cssVars['--cb-accent-9-l']) {
        lightness = parseFloat(cssVars['--cb-accent-9-l']);
    } else if (cssVars['--primary-main-l']) {
        lightness = parseFloat(cssVars['--primary-main-l']);
    } else {
        // Fallback if variables not found
        const style = getComputedStyle(document.body);
        const primaryMain = style.getPropertyValue('--cb-primary-main-l');
        lightness = parseFloat(primaryMain);
    }

    console.log('Lightness:', lightness);

    // Determine contrast type based on background lightness
    let contrastType = lightness <= 70 ? 'light' : 'dark';
    
    // Set contrast color CSS variable
    html.style.setProperty('--cb-accent-contrast', `var(--cb-accent-contrast-${contrastType})`);
}


function hexToLightness(hex) {
    // Remove the '#' if present
    hex = hex.replace('#', '');
  
    // Parse the hex color into RGB components
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    // Convert RGB to HSL to get lightness
    const rNorm = r / 255;
    const gNorm = g / 255;
    const bNorm = b / 255;
  
    const max = Math.max(rNorm, gNorm, bNorm);
    const min = Math.min(rNorm, gNorm, bNorm);
  
    // Lightness formula
    const lightness = ((max + min) / 2) * 100;
  
    return lightness.toFixed(2); // Return lightness as a percentage
  }

/**
 * Handles responsive search functionality for mobile devices
 * Adds fly-out behavior when screen width is below breakpoint
 */
function handleMobileSearch() {
    if ($window.width() < MOBILE_BREAKPOINT) {
        $('.nav-search .form-search .button.button-search').on('click', function(e) {
            const $navSearch = $('.nav-search');
            
            if (!$navSearch.hasClass('fly-out')) {
                e.preventDefault();
                
                // Add fly-out class and focus search input
                $navSearch.addClass('fly-out');
                $('.nav-search .form-search .input-text').focus();
                
                // Add close button
                const $closeButton = $('<button>')
                    .addClass('button button-close')
                    .html('<i class="fa fa-times"></i>');
                    
                $(this).after($closeButton);
            }
        });
    }
}

/**
 * Initialize event listeners and observers
 */
function initializeEventListeners() {
    // Watch for style changes on body element
    const observer = new MutationObserver(updateContrastColor);
    observer.observe(document.body, {
        attributes: true,
        attributeFilter: ['style']
    });

    // Window resize handler
    $window.on('resize', handleMobileSearch);
}

/**
 * Document ready handler
 */
$document.ready(function() {
    initializeEventListeners();
    handleMobileSearch();
    updateContrastColor();
});